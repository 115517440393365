// FourierSeriesVisualizerNode.js
import React, { useEffect, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import FourierSeriesVisualizer from './FourierSeriesVisualizer';

const FourierSeriesVisualizerNode = ({ data }) => {
  const nodeRef = useRef(null);

  useEffect(() => {
    const node = nodeRef.current;

    const stopPropagation = (e) => {
      e.stopPropagation();
    };

    // Add event listeners to stop propagation
    const events = [
      'mousedown',
      'mousemove',
      'mouseup',
      'touchstart',
      'touchmove',
      'touchend',
      'pointerdown',
      'pointermove',
      'pointerup',
    ];

    events.forEach((eventName) => {
      node.addEventListener(eventName, stopPropagation);
    });

    return () => {
      events.forEach((eventName) => {
        node.removeEventListener(eventName, stopPropagation);
      });
    };
  }, []);

  return (
    <div className="visual-node fourier-series-node" ref={nodeRef}>
      <Handle type="target" position="top" />
      <div className="visual-content">
        <FourierSeriesVisualizer />
      </div>
      <div className="explanation-text">
        {data.explanationText ||
          'Draw a shape on the left canvas, and see its Fourier series approximation animate on the right.'}
      </div>
      <Handle type="source" position="bottom" />
    </div>
  );
};

export default FourierSeriesVisualizerNode;
