// FSVisual/complex.js
export function addComplex(a, b) {
  return { re: a.re + b.re, im: a.im + b.im };
}

export function multiplyComplex(a, b) {
  return {
    re: a.re * b.re - a.im * b.im,
    im: a.re * b.im + a.im * b.re,
  };
}

export function divideComplexByScalar(a, scalar) {
  return { re: a.re / scalar, im: a.im / scalar };
}

export function magnitudeComplex(a) {
  return Math.sqrt(a.re ** 2 + a.im ** 2);
}

export function phaseComplex(a) {
  return Math.atan2(a.im, a.re);
}

export function expComplex(phi) {
  return { re: Math.cos(phi), im: Math.sin(phi) };
}
