import React, { useState } from 'react';
import LeftPane from './LeftPane';
import RightPane from './RightPane';
import { computeFourier } from './fourier';

const canvasSize = 400;

const FourierSeriesVisualizer = () => {
  const [fourierData, setFourierData] = useState(null);
  const [numCircles, setNumCircles] = useState(50);
  const [speed, setSpeed] = useState(1);
  const [reset, setReset] = useState(false);

  const handleCompleteDrawing = (path) => {
    console.log('Received path:', path); // Debugging
    const normalizedPath = normalizePath(path);
    const complexPath = normalizedPath.map(({ x, y }) => ({ re: x, im: y }));
    const fourierResult = computeFourier(complexPath);
    setFourierData(fourierResult);
  };

  const normalizePath = (path) => {
    // Normalization code (as before)
  };

  const handleReset = () => {
    setFourierData(null);
    setReset((prev) => !prev);
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <LeftPane
          onCompleteDrawing={handleCompleteDrawing}
          canvasSize={canvasSize}
          reset={reset}
        />
        <RightPane
          fourierData={fourierData}
          numCircles={numCircles}
          speed={speed}
          canvasSize={canvasSize}
          reset={reset}
        />
      </div>
      {/* Controls (as before) */}
    </div>
  );
};

export default FourierSeriesVisualizer;
