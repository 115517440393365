import React, { useEffect, useState } from 'react';
import './DesignChatbot.css';

function DesignChatbot({ selectedCourse }) {
  const [assistantId, setAssistantId] = useState('');
  const [systemInstructions, setSystemInstructions] = useState('');
  const [originalSystemInstructions, setOriginalSystemInstructions] = useState(''); // Track the original instructions
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModified, setIsModified] = useState(false); // Track if changes are made
  const [saveStatus, setSaveStatus] = useState('idle'); // idle, saving, success

  useEffect(() => {
    if (selectedCourse) {
      setLoading(true);
      fetch(`http://localhost:8080/get-assistant-messages?course=${selectedCourse}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Role': 'admin',
        },
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((data) => {
              throw new Error(data.error || 'Error fetching assistant data');
            });
          }
          return response.json();
        })
        .then((data) => {
          setAssistantId(data.assistant_id || 'Unknown Assistant');
          setSystemInstructions(data.system_instructions || 'No instructions provided.');
          setOriginalSystemInstructions(data.system_instructions || ''); // Store original instructions
          setError('');
        })
        .catch((err) => {
          console.error('Error fetching assistant data:', err);
          setError(err.message || 'Error fetching assistant data');
          setAssistantId('');
          setSystemInstructions('');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedCourse]);

  const handleSave = () => {
    if (!isModified) return;

    setSaveStatus('saving'); // Set status to saving

    // API call to save the updated system instructions
    fetch(`http://localhost:8080/update-assistant-prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Role': 'admin',
      },
      body: JSON.stringify({
        assistant_id: assistantId,
        updated_instructions: systemInstructions,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error || 'Error updating system instructions');
          });
        }
        return response.json();
      })
      .then(() => {
        setOriginalSystemInstructions(systemInstructions); // Update original instructions
        setIsModified(false); // Reset modified state
        setSaveStatus('success'); // Set status to success

        // Automatically reset save status after 2 seconds
        setTimeout(() => setSaveStatus('idle'), 2000);
      })
      .catch((err) => {
        console.error('Error updating system instructions:', err);
        alert(err.message || 'Error updating system instructions.');
        setSaveStatus('idle'); // Reset save status to idle
      });
  };

  const handleInstructionsChange = (e) => {
    const updatedInstructions = e.target.value;
    setSystemInstructions(updatedInstructions);
    setIsModified(updatedInstructions !== originalSystemInstructions); // Detect changes
  };

  return (
    <div className="design-chatbot-container">
      <h3>Design and Edit Your Chatbot Offering</h3>
      <p>Here you can design and edit the chatbot's responses and behavior.</p>
      <p>
        <strong>Selected Course:</strong> {selectedCourse || 'Loading...'}
      </p>
      <p>

        
      <p>
  <strong>Assistant ID:</strong>{' '}
  <span className="tooltip-container">
    <span className="assistant-id">{loading ? 'Loading...' : assistantId || 'N/A'}</span>
    <span className="tooltip-text">This ID cannot be changed.</span>
  </span>
</p>

     
     
      </p>

      <div>
        <h4>System Instructions:</h4>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          <textarea
            className="system-instructions"
            value={systemInstructions}
            onChange={handleInstructionsChange}
          />
        )}
      </div>

      <button
        className={`save-button ${isModified ? 'active' : ''}`}
        onClick={handleSave}
        disabled={!isModified || saveStatus === 'saving'}
      >
        Save
        {saveStatus === 'saving' && <span className="save-icon spinning" />}
        {saveStatus === 'success' && <span className="save-icon checkmark">✔</span>}
      </button>
    </div>
  );
}

export default DesignChatbot;
