import React, { useEffect, useState } from 'react';
import './UploadViewPDF.css';

function UploadViewPDF({ vectorStoreId }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!vectorStoreId) return;
    setLoading(true);

    fetch(`http://localhost:8080/get-files?vectorStoreId=${vectorStoreId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Role': 'admin',
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error || 'Error fetching files.');
          });
        }
        return response.json();
      })
      .then((data) => {
        setFiles(data.files || []);
        setError('');
      })
      .catch((err) => {
        console.error('Error fetching files:', err);
        setError(err.message || 'Error fetching files.');
      })
      .finally(() => setLoading(false));
  }, [vectorStoreId]);

  const handleDelete = (fileName) => {
    if (!window.confirm(`Delete file ${fileName}?`)) return;

    fetch(`http://localhost:8080/delete-file`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Role': 'admin',
      },
      body: JSON.stringify({ vectorStoreId, fileName }),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Error deleting file.');
        setFiles(files.filter((file) => file !== fileName));
      })
      .catch((err) => alert(err.message || 'Error deleting file.'));
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      alert('File size must be less than 5MB.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('vectorStoreId', vectorStoreId);

    setUploading(true);
    fetch(`http://localhost:8080/upload-file`, {
      method: 'POST',
      headers: { 'Role': 'admin' },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) throw new Error('Error uploading file.');
        setFiles([...files, file.name]);
      })
      .catch((err) => alert(err.message || 'Error uploading file.'))
      .finally(() => setUploading(false));
  };

  return (
    <div className="upload-view-pdf-container">
      <h3>Manage PDFs</h3>
      {loading ? (
        <p>Loading files...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <div className="file-list">
          {files.map((file) => (
            <div key={file} className="file-item">
              <span>{file}</span>
              <button
                className="delete-button"
                onClick={() => handleDelete(file)}
                title="Delete this file"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
      <div className="upload-section">
        <label htmlFor="file-upload" className="upload-label">
          {uploading ? 'Uploading...' : 'Upload PDF'}
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".pdf"
          onChange={handleUpload}
          disabled={uploading}
        />
      </div>
    </div>
  );
}

export default UploadViewPDF;
