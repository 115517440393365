import React, { useEffect, useRef } from 'react';

const RightPane = ({
  fourierData,
  numCircles = 50,
  speed = 1,
  canvasSize = 400,
  reset,
}) => {
  const canvasRef = useRef(null);
  const requestRef = useRef();
  const timeRef = useRef(0);
  const pathRef = useRef([]);

  useEffect(() => {
    if (!fourierData) return;

    console.log('fourierData received:', fourierData); // Debugging

    // Animation code (as before)
  }, [fourierData, numCircles, speed]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasSize}
      height={canvasSize}
      style={{ border: '1px solid black' }}
    />
  );
};

export default RightPane;
