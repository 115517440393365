import React, { useEffect, useState } from 'react';
import './AdminPage.css';
import {
  FaSlidersH,
  FaFileUpload,
  FaUsers,
  FaChartBar,
  FaFolderOpen,
} from 'react-icons/fa';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DesignChatbot from './DEC/DesignChatbot';
import UploadViewPDF from './UpdatedPDF/UploadViewPDF';
import UsersTable from '../UsersTable';

function AdminPage({ userInfo }) {
  const [hasAccess, setHasAccess] = useState(false);
  const [isInstructor, setIsInstructor] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [vectorStoreId, setVectorStoreId] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const roles = userInfo.roles || [];
    if (
      roles.includes('admin') ||
      roles.includes('instructor') ||
      roles.includes('TF')
    ) {
      setHasAccess(true);
    }
    setIsInstructor(roles.includes('admin') || roles.includes('instructor'));
  }, [userInfo]);

  const handleCourseSelect = (course) => {
    setSelectedCourse(course);
    setError('');

    // Fetch vector store for the selected course
    fetch(`http://localhost:8080/get-vector-store?course=${course}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Role': 'admin',
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.error || 'Error fetching vector store.');
          });
        }
        return response.json();
      })
      .then((data) => {
        setVectorStoreId(data.vectorStoreId || '');
      })
      .catch((err) => {
        console.error('Error fetching vector store:', err);
        setError(err.message || 'Error fetching vector store.');
        setVectorStoreId('');
      });
  };

  if (!hasAccess) {
    return <div className="access-denied">Access Denied</div>;
  }

  return (
    <div className="admin-page">
      <div className="sidebar">
        <div className="admin-info">
          <h2>Welcome, {userInfo.firstName}!</h2>
          <h4>Courses:</h4>
          <div className="course-list">
            {userInfo.courses.length > 0 ? (
              userInfo.courses.map((course) => (
                <div
                  key={course}
                  className="course-box"
                  onClick={() => handleCourseSelect(course)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleCourseSelect(course);
                    }
                  }}
                  aria-label={`Select course ${course}`}
                >
                  <div className="logo-placeholder">
                    <FaFolderOpen size={20} color="#4285f4" />
                  </div>
                  <div className="course-name">{course}</div>
                </div>
              ))
            ) : (
              <p>No courses assigned.</p>
            )}
          </div>
        </div>
      </div>

      <div className="content">
        {!selectedCourse ? (
          <div className="select-course-message">
            <h3>Please select a course to continue.</h3>
          </div>
        ) : (
          <div className="admin-tabs">
            <h3>Managing Course: {selectedCourse}</h3>
            <Tabs>
              <TabList>
                <Tab>
                  <FaSlidersH size={16} style={{ marginRight: '8px' }} />
                  Design and Edit Chatbot
                </Tab>
                <Tab>
                  <FaFileUpload size={16} style={{ marginRight: '8px' }} />
                  Manage PDFs
                </Tab>
                <Tab>
                  <FaChartBar size={16} style={{ marginRight: '8px' }} />
                  Activity
                </Tab>
                {isInstructor && (
                  <Tab>
                    <FaUsers size={16} style={{ marginRight: '8px' }} />
                    Manage Users
                  </Tab>
                )}
              </TabList>

              <TabPanel>
                <DesignChatbot selectedCourse={selectedCourse} />
              </TabPanel>
              <TabPanel>
                {error ? (
                  <p style={{ color: 'red' }}>{error}</p>
                ) : (
                  <UploadViewPDF vectorStoreId={vectorStoreId} />
                )}
              </TabPanel>
              <TabPanel>
                <h3>Activity</h3>
                <p>List of recent changes or actions taken.</p>
              </TabPanel>
              {isInstructor && (
                <TabPanel>
                  <h3>Manage Users</h3>
                  <UsersTable selectedCourse={selectedCourse} />
                </TabPanel>
              )}
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminPage;
