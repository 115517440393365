// FSVisual/fourier.js
import {
  addComplex,
  multiplyComplex,
  divideComplexByScalar,
  magnitudeComplex,
  phaseComplex,
  expComplex,
} from './complex';

export function computeFourier(path) {
  const N = path.length;
  const fourier = [];

  for (let k = 0; k < N; k++) {
    let sum = { re: 0, im: 0 };
    for (let n = 0; n < N; n++) {
      const phi = (-2 * Math.PI * k * n) / N; // Negative sign for forward transform
      const c = expComplex(phi);
      sum = addComplex(sum, multiplyComplex(path[n], c));
    }
    sum = divideComplexByScalar(sum, N);
    const freq = k;
    const amp = magnitudeComplex(sum);
    const phase = phaseComplex(sum);
    fourier.push({ re: sum.re, im: sum.im, freq, amp, phase });
  }

  // Sort by amplitude in descending order
  fourier.sort((a, b) => b.amp - a.amp);

  return fourier;
}
