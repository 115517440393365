// LeftPane.js
import React, { useRef, useEffect } from 'react';

const LeftPane = ({ onCompleteDrawing, canvasSize = 400, reset }) => {
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const isDrawingRef = useRef(false);
  const pathRef = useRef([]);

  // Initialize canvas context and clear canvas only on reset
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // Set up the canvas context
    const ctx = canvas.getContext('2d');
    ctxRef.current = ctx;
    ctx.clearRect(0, 0, canvasSize, canvasSize);
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 2;

    // Reset the path
    pathRef.current = [];
  }, [canvasSize, reset]); // Only runs on canvasSize or reset change

  // Event handlers
  const getCoordinates = (event) => {
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  };

  const startDrawing = (e) => {
    e.preventDefault();
    e.stopPropagation();
    isDrawingRef.current = true;
    const { x, y } = getCoordinates(e);
    pathRef.current = [{ x, y }];
    ctxRef.current.beginPath();
    ctxRef.current.moveTo(x, y);
  };

  const draw = (e) => {
    if (!isDrawingRef.current) return;
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = getCoordinates(e);
    pathRef.current.push({ x, y });
    ctxRef.current.lineTo(x, y);
    ctxRef.current.stroke();
  };

  const finishDrawing = (e) => {
    if (!isDrawingRef.current) return;
    e.preventDefault();
    e.stopPropagation();
    isDrawingRef.current = false;
    ctxRef.current.closePath();
    if (onCompleteDrawing) {
      onCompleteDrawing([...pathRef.current]); // Pass a copy of the path
    }
  };

  // Attach event listeners
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.addEventListener('pointerdown', startDrawing);
    canvas.addEventListener('pointermove', draw);
    canvas.addEventListener('pointerup', finishDrawing);
    canvas.addEventListener('pointerleave', finishDrawing);
    canvas.addEventListener('pointercancel', finishDrawing);

    return () => {
      canvas.removeEventListener('pointerdown', startDrawing);
      canvas.removeEventListener('pointermove', draw);
      canvas.removeEventListener('pointerup', finishDrawing);
      canvas.removeEventListener('pointerleave', finishDrawing);
      canvas.removeEventListener('pointercancel', finishDrawing);
    };
  }, [onCompleteDrawing]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasSize}
      height={canvasSize}
      style={{
        border: '1px solid black',
        touchAction: 'none',
        cursor: 'crosshair',
      }}
    />
  );
};

export default LeftPane;
